<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-1">

      <!-- Table Top -->
      <b-row>
        <!-- 타이틀 -->
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <h4 class="w-100 font-weight-bolder mb-1">Usege</h4>
        </b-col>
        <!-- 타이틀 END -->
        <!-- 리스트 표시 갯수 설정 -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label class="vertical-center" style="margin-top : 5px;">목록 수</label>
          <b-form-select
            v-model="perPage"
            :options="perPageOptions"
            size="sm"
            class="mt-0 per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>
        <!-- 리스트 표시 갯수 설정 END -->

        <!-- 검색 박스 / 버튼 -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <!-- 담당자 -->
            <b-form-input
              class="d-inline-block mr-1 col-lg-3"
              size="sm"
              placeholder="담당자"
            />
            <!-- 담당자 END -->
            <!-- 예약일자 -->
            <b-form-input
              class="d-inline-block mr-1 col-lg-3"
              size="sm"
              placeholder="예약일자"
            />
            <!-- 예약일자 END -->
            <!-- 예약시작시간 -->
            <b-form-input
              class="d-inline-block mr-1 col-lg-3"
              size="sm"
              placeholder="예약시작시간"
            />
            <!-- 예약시작시간 END -->
            <!-- 예약종료시간 -->
            <b-form-input
              class="d-inline-block mr-1 col-lg-3"
              size="sm"
              placeholder="예약종료시간"
            />
            <!-- 예약종료시간 END -->
            <!-- 지역 -->
            <b-form-input
              class="d-inline-block mr-1 col-lg-3"
              size="sm"
              placeholder="지역"
            />
            <!-- 지역 END -->
            <!-- 구분 -->
            <b-form-input
              class="d-inline-block mr-1 col-lg-3"
              size="sm"
              placeholder="구분"
            />
            <!-- 구분 END -->
            <!-- 검색 버튼 -->
            <b-button
              variant="primary"
              size='sm'
              class="btn-icon"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
            <!-- 검색 버튼 END -->
          </div>
        </b-col>
      </b-row>

    </div>
    <!-- 코덱 상태 조회 리스트 -->
    <b-table
      ref="refInvoiceListTable"
      :items="usegeListItem"
      responsive
      :fields="usegeListColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="조회된 목록이 없습니다."
      :sort-desc.sync="isSortDirDesc"
      class="position-relative text-center table-view"
    >
      <!-- 초기값 버튼 템플릿 -->
      <template #cell(setting)="data">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.usege-setting
            variant="primary"
            class="font-weight-bold btn btn-sm btn-primary"
            block
          >
            {{ data.value }}
        </b-button>
      </template>
      <!-- 초기값 버튼 템플릿 END -->
      <!-- On/Off 상태 -->
      <template #cell(status)="data">
        <b-avatar
          size="26"
          :variant="`${resolveInvoiceStatusVariantAndIcon(data.item.status).variant}`"
        >
          <span></span>
        </b-avatar>
      </template>
      <!-- On/Off 상태 END -->
      <!-- Fault 상태 -->
      <template #cell(Fault)="data">
        <b-avatar
          size="26"
          :variant="`${resolveInvoiceStatusVariantAndIcon(data.item.Fault).variant}`"
        >
          <span></span>
        </b-avatar>
      </template>
      <!-- Fault 상태 END -->
      <!-- Activation 상태 -->
      <template #cell(Activation)="data">
        <b-avatar
          size="26"
          :variant="`${resolveInvoiceStatusVariantAndIcon(data.item.Activation).variant}`"
        >
          <span></span>
        </b-avatar>
      </template>
      <!-- Activation 상태 END -->
      <!-- Usage 상태 -->
      <template #cell(Usage)="data">
        <b-avatar
          size="26"
          :variant="`${resolveInvoiceStatusVariantAndIcon(data.item.Usage).variant}`"
        >
          <span></span>
        </b-avatar>
      </template>
      <!-- Usage 상태 END -->

    </b-table>
    <!-- 코덱 상태 조회 리스트 END -->
    <!-- 페이지네이션 -->
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"></span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
    <!-- 페이지네이션 END -->
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BAvatar, BFormSelect, BButton, VBToggle,
  BPagination,
} from 'bootstrap-vue'

import { ref, computed, watch } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BAvatar,
    BFormSelect,
    BButton,
    
    BPagination,
  },
  data() {
    return {
      data: {
      },
    }
  },
  setup() {
    const refInvoiceListTable = ref(null)
    const perPage = ref(10)
    const totalInvoices = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusFilter = ref(null)

    const usegeListColumns = [
        { key: 'sn', label: '순서'},
        { key: 'setting', label: '초기값', },
        { key: 'name', label: '회의실' },
        { key: 'user', label: '담당자'},
        { key: '예약일자', label: '예약일자' },
        { key: '예약시작', label: '예약시작' },
        { key: '예약종료', label: '예약종료' },
        { key: '지역', label: '지역' },
        { key: '구분', label: '구분' },
        { key: 'status', label: 'On/Off', },
        { key: 'Fault', label: 'Fault', },
        { key: 'Activation', label: 'Activation', },
        { key: 'Usage', label: 'Usage', },
    ]
    const usegeListItem = [
        { 'name': 'Room 1',
          'user': '',
          '예약일자': '2021-02-11',
          '예약시작': '10:00',
          '예약종료': '12:13',
          '지역': '',
          '구분': '',
          'status': 'On',
          'Fault': 'On',
          'Activation': 'On',
          'Usage': 'On',
          'setting': '설정',
          'sn': '1',
        },
        { 'name': 'Room 1',
          'user': '',
          '예약일자': '2021-02-11',
          '예약시작': '10:00',
          '예약종료': '12:13',
          '지역': '',
          '구분': '',
          'status': 'On',
          'Fault': 'Off',
          'Activation': 'On',
          'Usage': 'On',
          'setting': '설정',
          'sn': '2',
        },
        { 'name': 'Room 1',
          'user': '',
          '예약일자': '2021-02-11',
          '예약시작': '10:00',
          '예약종료': '12:13',
          '지역': '',
          '구분': '',
          'status': 'On',
          'Fault': '',
          'Activation': 'On',
          'Usage': 'On',
          'setting': '설정',
          'sn': '3',
        },
        { 'name': 'Room 1',
          'user': '',
          '예약일자': '2021-02-11',
          '예약시작': '10:00',
          '예약종료': '12:13',
          '지역': '',
          '구분': '',
          'status': 'On',
          'Fault': 'On',
          'Activation': 'On',
          'Usage': 'On',
          'setting': '설정',
          'sn': '4',
        },
        { 'name': 'Room 1',
          'user': '',
          '예약일자': '2021-02-11',
          '예약시작': '10:00',
          '예약종료': '12:13',
          '지역': '',
          '구분': '',
          'status': 'On',
          'Fault': 'On',
          'Activation': 'On',
          'Usage': 'On',
          'setting': '설정',
          'sn': '5',
        },
        { 'name': 'Room 1',
          'user': '',
          '예약일자': '2021-02-11',
          '예약시작': '10:00',
          '예약종료': '12:13',
          '지역': '',
          '구분': '',
          'status': 'On',
          'Fault': 'On',
          'Activation': 'On',
          'Usage': 'On',
          'setting': '설정',
          'sn': '6',
        },
        { 'name': 'Room 1',
          'user': '',
          '예약일자': '2021-02-11',
          '예약시작': '10:00',
          '예약종료': '12:13',
          '지역': '',
          '구분': '',
          'status': 'On',
          'Fault': 'On',
          'Activation': 'On',
          'Usage': 'On',
          'setting': '설정',
          'sn': '7',
        },
        { 'name': 'Room 1',
          'user': '',
          '예약일자': '2021-02-11',
          '예약시작': '10:00',
          '예약종료': '12:13',
          '지역': '',
          '구분': '',
          'status': 'On',
          'Fault': 'On',
          'Activation': 'On',
          'Usage': 'On',
          'setting': '설정',
          'sn': '8',
        },
        { 'name': 'Room 1',
          'user': '',
          '예약일자': '2021-02-11',
          '예약시작': '10:00',
          '예약종료': '12:13',
          '지역': '',
          '구분': '',
          'status': 'On',
          'Fault': 'On',
          'Activation': 'On',
          'Usage': 'On',
          'setting': '설정',
          'sn': '9',
        },
        { 'name': 'Room 1',
          'user': '',
          '예약일자': '2021-02-11',
          '예약시작': '10:00',
          '예약종료': '12:13',
          '지역': '',
          '구분': '',
          'status': 'On',
          'Fault': 'On',
          'Activation': 'On',
          'Usage': 'On',
          'setting': '설정',
          'sn': '10',
        },
    ]
    totalInvoices.value = usegeListItem.length
    const dataMeta = computed(() => {
        const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
            return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalInvoices.value,
            }
        })
    const refetchData = () => {
        refInvoiceListTable.value.refresh()
    }
    watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
    })
    const resolveInvoiceStatusVariantAndIcon = status => {
        if (status === 'Partial Payment') return { variant: 'warning', icon: 'PieChartIcon' }
        if (status === 'On') return { variant: 'success', icon: 'CheckCircleIcon' }
        if (status === 'On3') return { variant: 'info', icon: 'ArrowDownCircleIcon' }
        if (status === 'Draft') return { variant: 'primary', icon: 'SaveIcon' }
        if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' }
        if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' }
        if (status === 'On2') return { variant: 'primary', icon: 'CheckCircleIcon' }
        if (status === '') return { variant: 'danger', icon: 'InfoIcon' }
        return { variant: 'secondary', icon: 'XIcon' }
    }
    const resolveClientAvatarVariant = status => {
        if (status === '예약일자') return 'primary'
        if (status === '예약시작') return 'danger'
        if (status === '예약종료') return 'secondary'
        if (status === 'Draft') return 'warning'
        if (status === 'Sent') return 'info'
        if (status === 'Past Due') return 'success'
        return 'primary'
    }
    

    return {
      perPage,
      perPageOptions,
      usegeListItem,
      usegeListColumns,
      sortBy,
      isSortDirDesc,
      dataMeta,
      totalInvoices,
      currentPage,
      refInvoiceListTable,

      avatarText,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
